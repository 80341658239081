import { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

export const useCustomFetch = () => {
  const { googleSignOut, microsoftSignOut } = useContext(AuthContext)

  const customFetch = async (urlPath, method, body) => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      }
      if (import.meta.env.VITE_NODE_ENV !== 'willUseHttpOnly') {
        options.headers.authorization = localStorage.getItem('access_token')
        options.headers.signinmethod = localStorage.getItem('signin_method')
      }
      if (body) options.body = JSON.stringify(body)

      const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api${urlPath}`, options)

      if (response.status === 401) {
        console.error('Error fetching: unauthorized')
        if (localStorage.getItem('signin_method') === 'google') {
          googleSignOut()
        } else if (localStorage.getItem('signin_method') === 'microsoft') {
          microsoftSignOut()
        } else {
          localStorage.clear()
          window.location.href = '/'
        }
        return { error: 'Unauthorized' }
      }
      if (!response.ok) throw await response.json()

      if (import.meta.env.VITE_NODE_ENV !== 'willUseHttpOnly') {
        response.headers.forEach((value, key) => {
          if (key === 'set-access-token') {
            localStorage.setItem('access_token', value)
          }
        })
      }

      return await response.json()
    } catch (error) {
      console.error('Error fetching:', error)
      return { error }
    }
  }

  return customFetch
}

export const authCustomFetch = async (urlPath, method, body) => {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (import.meta.env.VITE_NODE_ENV !== 'willUseHttpOnly') {
      options.headers.authorization = localStorage.getItem('access_token')
      options.headers.signinmethod = localStorage.getItem('signin_method')
    }
    if (body) options.body = JSON.stringify(body)

    const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api${urlPath}`, options)

    if (!response.ok) throw await response.json()

    if (import.meta.env.VITE_NODE_ENV !== 'willUseHttpOnly') {
      response.headers.forEach((value, key) => {
        if (key === 'set-access-token') {
          localStorage.setItem('access_token', value)
        }
      })
    }

    return await response.json()
  } catch (error) {
    console.error('Error fetching:', error)
    return { error }
  }
}
